<template>
    <div class="JHost">
        JHost
    </div>
</template>

<script>

    export default {
        name: 'JHost',
        data () {
            return {

            }
        },
        methods: {

        },

        mounted () {


        },
    }
</script>

<style>
    .JHost{

    }

</style>